<template>
    <header class="header" :class="hasHeaderBackground ? 'header--has-background' : ''">
        <section class="section section--header-background" v-if="hasHeaderBackground">                
            <svg class="section__background" xmlns="http://www.w3.org/2000/svg" width="1920" height="342" viewBox="0 0 1920 342" preserveAspectRatio="none">
                <defs>
                    <clipPath id="main-background-mask" clipPathUnits="objectBoundingBox">
                        <path d="M1 0H0C0 0 0 0.508587 0 0.871329C0.411324 0.265303 0.731052 1.3575 1 0.871329C1 0.252251 1 0 1 0Z" fill="#EAEAEA"/>
                    </clipPath>
                </defs>
                <path fill="#fa5d18" d="M1919.999,0.0 L0.0,0.0 C0.0,0.0 0.0,169.270 0.0,289.998 C789.741,88.299 1397.457,474.248 1919.999,289.998 C1919.999,83.955 1919.999,0.0 1919.999,0.0 Z"/>
            </svg>
            <div class="section__background section__background--masked">
                <picture>
                    <source srcset="@/assets/images/main-screen-secondary-2560.jpg" media="(min-width: 992px)">
                    <source srcset="@/assets/images/main-screen-secondary-1000.jpg" media="(min-width: 576px)">
                    <img src="@/assets/images/main-screen-secondary-580.jpg" alt="Main background image" />
                </picture>
            </div>
        </section>
        <v-container>
            <v-row>
                <v-col cols="6">
                    <div class="logo-wrap">
                        <router-link to="/" class="logo">
                            <span class="logo__title">Духовное послание</span>
                            <span class="logo__caption">Твоя воля и чувства вечны</span>
                        </router-link>
                    </div>
                </v-col>
                <v-col cols="6">
                    <nav class="navigation">
                        <ul class="navigation-list">
                            <li class="navigation-list__item">
                                <v-menu v-if="language"
                                    eager
                                    origin="center center"
                                    transition="scale-transition"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            color="default"
                                            class="language-select-btn text-sm-body-2 text-md-body-1"
                                            v-bind="attrs"
                                            v-on="on"
                                            text
                                        >
                                            <svg width="20px" height="15px" class="country-flag" role="img">
                                                <use :xlink:href="require(`@/assets/images/icons/flags_4x3.svg`) + `#${language.iso}`" />
                                            </svg>
                                            <span v-if="$vuetify.breakpoint.smAndUp">
                                                {{ language.name }}
                                            </span>
                                            <v-icon
                                                v-if="$vuetify.breakpoint.smAndUp"
                                                :size="$vuetify.breakpoint.smOnly ? 20 : 22"
                                                color="primary"
                                                class="mr-n2 ml-1"
                                            >
                                                mdi-chevron-down
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list dense max-height="300px" z-index="100000">
                                        <v-list-item
                                            v-for="(l, i) in languages"
                                            :key="i"
                                            @click="updateLanguage(l)"
                                        >
                                            <svg width="20px" height="15px" class="country-flag mr-2" role="img">
                                                <use :xlink:href="require(`@/assets/images/icons/flags_4x3.svg`) + `#${l.iso}`" />
                                            </svg>
                                            <v-list-item-title>{{ l.name }}</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </li>
                            <li class="navigation-list__item">
                                <a href="/console/sign-in" class="navigation-list__item-link link link--hover">
                                    <span>Вход</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </v-col>
            </v-row>
        </v-container>
        
    </header>
</template>

<script>
export default {
    props: {
        hasHeaderBackground: {
            type: Boolean,
            default: false,
        }
    },
    data () {
        return {
            languages: [],
        }
    },
    created () {
        this.searchLanguages()
    },
    computed: {
        language () {
            return this.languages.filter(lang => lang.iso === this.$store.getters.language)[0]
        },
        languageISO () {
            return this.$store.getters.language
        },
    },
    watch: {
        language () {
            if (this.language) {
                this.$root.$i18n.locale = this.language.iso
            }
        },
    },
    methods: {
        searchLanguages () {
            this.$axios.post('/api/landing/resource/language')
                .then(res => {
                    if (res.data.success) {
                        this.languages = res.data.data
                    }
                })
                .catch(() => {
                    this.languages = []
                })
        },

        updateLanguage (language) {
            this.$axios.post('/api/landing/user/language/update', {language_id: language.id}).then(() => {
                this.$store.commit('updateLanguage', language.iso)
                this.$root.$i18n.locale = language.iso
                this.$router.go()
            })
        },
    },
}
</script>

<style lang="scss" scoped>
    .v-menu__content {
        background: map-get($material-light, 'surface');
    }
    .country-flag {
        min-width: fit-content;
        z-index: 1;
    }
    .link:not(:hover) {
        color: inherit;
    }
</style>
