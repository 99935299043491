<template>
    <footer class="footer" :class="isBackgroundWhite ? 'section--white' : ''">
        <v-container>
            <div class="d-flex flex-column flex-md-row">
                <div class="footer__info">
                    <div class="footer__info-logo">
                        <span class="footer__info-logo-title footer__info-logo-title--ru">Духовное послание</span>
                        <span class="footer__info-logo-caption">Твоя воля и чувства вечны</span>
                    </div>
                    <p class="footer__info-copyright mb-2">© Духовное послание | 2021</p>
                </div>
                <div class="footer__actions">
                    <div class="d-flex align-center mb-3">
                        <svg class="icon mr-2" width="16px" height="16px" role="img">
                            <use xlink:href="@/assets/images/icons/icons.svg#icon-share-alt"></use>
                        </svg>
                        <span>Поделиться</span>
                    </div>
                    <div class="footer__actions-socials d-flex">
                        <a :href="`https://vk.com/share.php?url=${url}&title=${title}`"
                            target="_blank"
                            class="footer__actions-socials-item vk"
                            @click.prevent="shareVk"
                        >
                            <button class="button button--flat-grey button--round ripple">
                                <svg class="icon" width="16px" height="16px" role="img">
                                    <use xlink:href="@/assets/images/icons/icons.svg#icon-vk"></use>
                                </svg>
                            </button>
                        </a>
                        <a :href="`http://www.facebook.com/sharer.php?u=${url}`"
                            target="_blank"
                            class="footer__actions-socials-item facebook"
                            @click.prevent="shareFacebook"
                        >
                            <button type="button" class="button button--flat-grey button--round ripple">
                                <svg class="icon" width="16px" height="16px" role="img">
                                    <use xlink:href="@/assets/images/icons/icons.svg#icon-facebook"></use>
                                </svg>
                            </button>
                        </a>
                        <a :href="`https://connect.ok.ru/offer?url=${url}&title=${title}`"
                            target="_blank"
                            class="footer__actions-socials-item odnoklassniki"
                            @click.prevent="shareOdnoklassniki"
                        >
                            <button type="button" class="button button--flat-grey button--round ripple">
                                <svg class="icon" width="16px" height="16px" role="img">
                                    <use xlink:href="@/assets/images/icons/icons.svg#icon-odnoklassniki"></use>
                                </svg>
                            </button>
                        </a>
                        <a :href="`http://twitter.com/share?text=${this.title}&url=${this.url}&counturl=${this.url}`"
                            target="_blank"
                            class="footer__actions-socials-item twitter"
                            @click.prevent="shareTwitter"
                        >
                            <button type="button" class="button button--flat-grey button--round ripple">
                                <svg class="icon" width="16px" height="16px" role="img">
                                    <use xlink:href="@/assets/images/icons/icons.svg#icon-twitter"></use>
                                </svg>
                            </button>
                        </a>
                        <a :href="`https://wa.me/?text=${this.title}: ${this.url}`"
                            target="_blank"
                            class="footer__actions-socials-item whatsApp"
                            @click.prevent="shareWhatsApp"
                        >
                            <button type="button" class="button button--flat-grey button--round ripple">
                                <svg class="icon" width="16px" height="16px" role="img">
                                    <use xlink:href="@/assets/images/icons/icons.svg#icon-whatsapp"></use>
                                </svg>
                            </button>
                        </a>
                        <a :href="`https://telegram.me/share/url?url=${this.url}&text=${this.title}`"
                            target="_blank"
                            class="footer__actions-socials-item telegram"
                            @click.prevent="shareTelegram"
                        >
                            <button type="button" class="button button--flat-grey button--round ripple">
                                <svg class="icon" width="16px" height="16px" role="img">
                                    <use xlink:href="@/assets/images/icons/icons.svg#icon-telegram"></use>
                                </svg>
                            </button>
                        </a>
                    </div>
                    <div class="footer__actions-links">
                        <router-link to="/how-it-works" class="footer__actions-links-item link link--underscore"><span><b>Как это работает?</b></span></router-link>
                        <router-link to="/feedback" class="footer__actions-links-item link link--underscore"><span>Задать вопрос</span></router-link>
                        <router-link to="/privacy" class="footer__actions-links-item link link--underscore"><span>Политика обработки персональных данных</span></router-link>
                        <router-link to="/agreement" class="footer__actions-links-item link link--underscore"><span>Публичная оферта</span></router-link>
                    </div>
                </div>
                <div class="footer__info">
                    <div class="footer__info-logo">
                        <span class="footer__info-logo-title">Spiritual Will</span>
                        <span class="footer__info-logo-caption">Your will and feelings are eternal</span>
                    </div>
                    <p class="footer__info-copyright">© Spiritual Will | 2021</p>
                </div>
            </div>
        </v-container>
    </footer>
</template>

<script>
export default {
    props: {
        isBackgroundWhite: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            domain: process.env.VUE_APP_API_DOMAIN,
            url: encodeURIComponent(window.location.href),
            title: encodeURIComponent(document.title),
        }
    },
    methods: {
        shareVk () {
            let url = 'https://vk.com/share.php?'
            url += 'url=' + this.url
            url += '&title=' + this.title
            url += '&noparse=false'
            this.sharePopup(url)
        },
        shareFacebook () {
            let url = 'http://www.facebook.com/sharer.php?u=' + this.url
            this.sharePopup(url)
        },
        shareOdnoklassniki () {
            let url = 'https://connect.ok.ru/offer?'
            url += 'url=' + this.url
            url += '&title=' + this.title
            this.sharePopup(url)
        },
        shareTwitter () {
            let url = 'http://twitter.com/share?'
            url += 'text=' + this.title
            url += '&url=' + this.url
            url += '&counturl=' + this.url
            this.sharePopup(url)
        },
        shareWhatsApp () {
            let url = 'https://wa.me/?'
            url  += 'text=' + this.title + ': ' + this.url;
            this.sharePopup(url)
        },
        shareTelegram () {
            let url = 'https://telegram.me/share/url?'
            url += 'url=' + this.url
            url += '&text=' + this.title
            this.sharePopup(url)
        },
        sharePopup (url) {
            window.open(url,'sharer','status=0,toolbar=0,width=750,height=550')
        },
    }
}
</script>
